import React from 'react'
// import { Navigate } from 'react-router-dom'
import { RoleType } from '../types/auth'
// import paths from '../routes/paths'
// import { useAppSelector } from '@/redux/store'

interface ProtectedRouteProps {
  allowedRoles?: RoleType[]
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // const { isAuthenticated, userRole } = useAppSelector()

  // if (!isAuthenticated) {
  //   return <Navigate to={paths.HOME} />
  // }

  // if (!allowedRoles?.includes(userRole!)) {
  //   return <Navigate to={paths.UNAUTHORIZED} />
  // }

  return <>{children}</>
}

export default ProtectedRoute
