type IconProps = {
  size?: number
  color?: string
  width?: number
  height?: number
  className?: string
}

const HomeIcon = ({ size = 16, color = '#2DC492', width = size, height = size + 2, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    className={className}
  >
    <path
      d="M2 14.5C2 15.3284 2.67157 16 3.5 16C4.32843 16 5 15.3284 5 14.5V12C5 10.8954 5.89543 10 7 10H9C10.1046 10 11 10.8954 11 12V14.5C11 15.3284 11.6716 16 12.5 16C13.3284 16 14 15.3284 14 14.5V8C14 7.37049 13.7036 6.77771 13.2 6.4L9.2 3.4C8.48889 2.86667 7.51111 2.86667 6.8 3.4L2.8 6.4C2.29639 6.77771 2 7.37049 2 8V14.5ZM2 18C0.89543 18 0 17.1046 0 16V7C0 6.37049 0.296388 5.77771 0.8 5.4L6.8 0.9C7.51111 0.366667 8.48889 0.366667 9.2 0.9L15.2 5.4C15.7036 5.77771 16 6.37049 16 7V16C16 17.1046 15.1046 18 14 18H11C9.89543 18 9 17.1046 9 16V13C9 12.4477 8.55229 12 8 12C7.44772 12 7 12.4477 7 13V16C7 17.1046 6.10457 18 5 18H2Z"
      fill={color}
    />
  </svg>
)

const RequestIcon = ({ size = 17, color = '#2DC492', width = size, height = size + 4, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 21"
    fill="none"
    className={className}
  >
    <path
      d="M4.53906 16.1331H12.5391V14.1331H4.53906V16.1331ZM4.53906 12.1331H12.5391V10.1331H4.53906V12.1331ZM2.53906 20.1331C1.98906 20.1331 1.51823 19.9372 1.12656 19.5456C0.734896 19.1539 0.539062 18.6831 0.539062 18.1331V2.13306C0.539062 1.58306 0.734896 1.11222 1.12656 0.720557C1.51823 0.32889 1.98906 0.133057 2.53906 0.133057H10.5391L16.5391 6.13306V18.1331C16.5391 18.6831 16.3432 19.1539 15.9516 19.5456C15.5599 19.9372 15.0891 20.1331 14.5391 20.1331H2.53906ZM9.53906 7.13306V2.13306H2.53906V18.1331H14.5391V7.13306H9.53906Z"
      fill={color}
    />
  </svg>
)

const StatisticsIcon = ({ color = '#2DC492', width = 21, height = 21, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.7702 8.98552H19.4785C19.6445 8.98486 19.8079 8.94454 19.9552 8.86793C20.1024 8.79132 20.2292 8.68063 20.325 8.54508C20.4208 8.40953 20.4828 8.25305 20.5059 8.08867C20.529 7.9243 20.5125 7.75679 20.4577 7.6001C19.9498 6.13131 19.1148 4.79709 18.0159 3.69815C16.917 2.59921 15.5827 1.76427 14.1139 1.25635C13.9572 1.20158 13.7897 1.18504 13.6254 1.20811C13.461 1.23119 13.3045 1.29321 13.169 1.38901C13.0334 1.48481 12.9227 1.61162 12.8461 1.75887C12.7695 1.90611 12.7292 2.06954 12.7285 2.23552V7.94385C12.7285 8.22012 12.8383 8.48507 13.0336 8.68042C13.229 8.87577 13.4939 8.98552 13.7702 8.98552ZM14.8118 3.8501C16.082 4.57859 17.1354 5.632 17.8639 6.90219H14.8118V3.8501Z"
      fill={color}
    />
    <path
      d="M20.7282 11.4125C20.6308 11.3046 20.5119 11.2183 20.3792 11.1591C20.2464 11.0999 20.1028 11.0691 19.9574 11.0688H10.6449V1.75626C10.6446 1.6109 10.6138 1.46722 10.5546 1.33447C10.4954 1.20171 10.4091 1.08281 10.3011 0.985424C10.1918 0.887124 10.0628 0.813097 9.92278 0.768212C9.78274 0.723327 9.63478 0.708603 9.48864 0.725007C7.53318 0.932647 5.67642 1.68956 4.1331 2.90822C2.58978 4.12689 1.42294 5.75751 0.767506 7.61153C0.112077 9.46555 -0.00516377 11.4672 0.429341 13.3851C0.863847 15.3029 1.83235 17.0586 3.22285 18.4491C4.61335 19.8396 6.36904 20.8081 8.2869 21.2426C10.2048 21.6771 12.2064 21.5599 14.0605 20.9045C15.9145 20.2491 17.5451 19.0822 18.7638 17.5389C19.9824 15.9956 20.7393 14.1388 20.947 12.1833C20.9632 12.0468 20.9522 11.9083 20.9147 11.776C20.8771 11.6437 20.8138 11.5202 20.7282 11.4125ZM10.6449 19.4021C8.61375 19.4061 6.65107 18.6681 5.12573 17.3269C3.60039 15.9857 2.61741 14.1335 2.36154 12.1185C2.10567 10.1036 2.59453 8.06453 3.73624 6.38462C4.87794 4.70472 6.59388 3.49963 8.56156 2.99584V12.1104C8.56156 12.3867 8.67131 12.6516 8.86666 12.847C9.06201 13.0423 9.32696 13.1521 9.60323 13.1521H18.7178C18.2558 14.9416 17.2119 16.5267 15.7505 17.6581C14.2891 18.7895 12.4931 19.403 10.6449 19.4021Z"
      fill={color}
    />
  </svg>
)

const AgreementIcon = ({ color = '#2DC492', width = 20, height = 20, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M6 7V5H15V7H6ZM6 10V8H15V10H6ZM9 20H3C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V9.025C17.6667 8.99167 17.3292 9.00417 16.9875 9.0625C16.6458 9.12083 16.3167 9.225 16 9.375V2H5V14H11L9 16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18H9V20ZM11 20V16.925L16.525 11.425C16.675 11.275 16.8417 11.1667 17.025 11.1C17.2083 11.0333 17.3917 11 17.575 11C17.775 11 17.9667 11.0375 18.15 11.1125C18.3333 11.1875 18.5 11.3 18.65 11.45L19.575 12.375C19.7083 12.525 19.8125 12.6917 19.8875 12.875C19.9625 13.0583 20 13.2417 20 13.425C20 13.6083 19.9667 13.7958 19.9 13.9875C19.8333 14.1792 19.725 14.35 19.575 14.5L14.075 20H11ZM12.5 18.5H13.45L16.475 15.45L16.025 14.975L15.55 14.525L12.5 17.55V18.5ZM16.025 14.975L15.55 14.525L16.475 15.45L16.025 14.975Z"
      fill={color}
    />
  </svg>
)

const OrdersIcon = ({ color = '#2DC492', width = 19, height = 20, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M4 12H7.675C7.85833 11.6167 8.07083 11.2583 8.3125 10.925C8.55417 10.5917 8.825 10.2833 9.125 10H4V12ZM4 16H7.075C7.025 15.6667 7 15.3333 7 15C7 14.6667 7.025 14.3333 7.075 14H4V16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V8.3C15.6833 8.2 15.3583 8.125 15.025 8.075C14.6917 8.025 14.35 8 14 8V7H9V2H2V18H7.675C7.85833 18.3833 8.07083 18.7417 8.3125 19.075C8.55417 19.4083 8.825 19.7167 9.125 20H2ZM14 10C15.3833 10 16.5625 10.4875 17.5375 11.4625C18.5125 12.4375 19 13.6167 19 15C19 16.3833 18.5125 17.5625 17.5375 18.5375C16.5625 19.5125 15.3833 20 14 20C12.6167 20 11.4375 19.5125 10.4625 18.5375C9.4875 17.5625 9 16.3833 9 15C9 13.6167 9.4875 12.4375 10.4625 11.4625C11.4375 10.4875 12.6167 10 14 10ZM14.35 17.15C14.25 17.05 14.1333 17 14 17C13.8667 17 13.75 17.05 13.65 17.15C13.55 17.25 13.5 17.3667 13.5 17.5C13.5 17.6333 13.55 17.75 13.65 17.85C13.75 17.95 13.8667 18 14 18C14.1333 18 14.25 17.95 14.35 17.85C14.45 17.75 14.5 17.6333 14.5 17.5C14.5 17.3667 14.45 17.25 14.35 17.15ZM13.5 12H14.5V16H13.5V12Z"
      fill={color}
    />
  </svg>
)

const PharmInspectionsIcon = ({ color = '#2DC492', width = 22, height = 16, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    className={className}
  >
    <path
      d="M0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.7958 11.5625 17.3875 10.8875C16.9792 10.2125 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42083 18.05 9.6625C18.8 9.90417 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.2542 21.525 11.6625C21.8417 12.0708 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97917 13.3 7.9375C13.0167 7.89583 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3958 6.675 13.6375 6.025C13.8792 5.375 14 4.7 14 4C14 3.3 13.8792 2.625 13.6375 1.975C13.3958 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116667 13.0667 0.0625 13.3 0.0375C13.5333 0.0125 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9542 12.85 13.8625 12.7C13.7708 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5625 10.775 11.3375C9.85833 11.1125 8.93333 11 8 11C7.06667 11 6.14167 11.1125 5.225 11.3375C4.30833 11.5625 3.4 11.9 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z"
      fill={color}
    />
  </svg>
)

const ConclusionsIcon = ({ color = '#2DC492', width = 16, height = 20, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    className={className}
  >
    <path
      d="M6.95 16L12.6 10.35L11.15 8.9L6.925 13.125L4.825 11.025L3.4 12.45L6.95 16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z"
      fill={color}
    />
  </svg>
)

const InspectionCasesIcon = ({ color = '#2DC492', width = 22, height = 16, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    className={className}
  >
    <path
      d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H8L10 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4H9.175L7.175 2H2V14L4.4 6H21.5L18.925 14.575C18.7917 15.0083 18.5458 15.3542 18.1875 15.6125C17.8292 15.8708 17.4333 16 17 16H2ZM4.1 14H17L18.8 8H5.9L4.1 14Z"
      fill={color}
    />
  </svg>
)

const CertificateRegistryIcon = ({ color = '#2DC492', width = 18, height = 18, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path
      d="M9 7H14V5H9V7ZM9 13H14V11H9V13ZM6 8C6.55 8 7.02083 7.80417 7.4125 7.4125C7.80417 7.02083 8 6.55 8 6C8 5.45 7.80417 4.97917 7.4125 4.5875C7.02083 4.19583 6.55 4 6 4C5.45 4 4.97917 4.19583 4.5875 4.5875C4.19583 4.97917 4 5.45 4 6C4 6.55 4.19583 7.02083 4.5875 7.4125C4.97917 7.80417 5.45 8 6 8ZM6 14C6.55 14 7.02083 13.8042 7.4125 13.4125C7.80417 13.0208 8 12.55 8 12C8 11.45 7.80417 10.9792 7.4125 10.5875C7.02083 10.1958 6.55 10 6 10C5.45 10 4.97917 10.1958 4.5875 10.5875C4.19583 10.9792 4 11.45 4 12C4 12.55 4.19583 13.0208 4.5875 13.4125C4.97917 13.8042 5.45 14 6 14ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16Z"
      fill={color}
    />
  </svg>
)

const EmployeesIcon = ({ color = '#2DC492', width = 22, height = 16, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    className={className}
  >
    <path
      d="M0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.7958 11.5625 17.3875 10.8875C16.9792 10.2125 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42083 18.05 9.6625C18.8 9.90417 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.2542 21.525 11.6625C21.8417 12.0708 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97917 13.3 7.9375C13.0167 7.89583 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3958 6.675 13.6375 6.025C13.8792 5.375 14 4.7 14 4C14 3.3 13.8792 2.625 13.6375 1.975C13.3958 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116667 13.0667 0.0625 13.3 0.0375C13.5333 0.0125 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9542 12.85 13.8625 12.7C13.7708 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5625 10.775 11.3375C9.85833 11.1125 8.93333 11 8 11C7.06667 11 6.14167 11.1125 5.225 11.3375C4.30833 11.5625 3.4 11.9 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z"
      fill={color}
    />
  </svg>
)

const ReportsIcon = ({ color = '#2DC492', width = 18, height = 18, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path
      d="M0 18V16L2 14V18H0ZM4 18V12L6 10V18H4ZM8 18V10L10 12.025V18H8ZM12 18V12.025L14 10.025V18H12ZM16 18V8L18 6V18H16ZM0 12.825V10L7 3L11 7L18 0V2.825L11 9.825L7 5.825L0 12.825Z"
      fill={color}
    />
  </svg>
)

const SideBarIcons = {
  HomeIcon,
  InspectionCasesIcon,
  RequestIcon,
  ConclusionsIcon,
  CertificateRegistryIcon,
  PharmInspectionsIcon,
  StatisticsIcon,
  AgreementIcon,
  OrdersIcon,
  EmployeesIcon,
  ReportsIcon,
}

export default SideBarIcons
