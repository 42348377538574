import { IconSearch } from '@/utils/icons/search'
import ThemeIcon from '@/assets/icons/ic_theme.svg'
import { Input } from 'antd'
import styled from 'styled-components'
import { NotificationIcon, PreviewIcon, UserIcon } from '@/utils/icons'
import { LogoutIcon } from '@/utils/icons/login'
import { logout } from '@/redux/reducers/auth'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { useNavigate } from 'react-router-dom'
import paths from '@/routes/paths'
import { getMe } from '@/redux/selectors/user'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector(getMe)
  const handleLogout = () => {
    dispatch(logout())
    navigate(paths.HOME)
  }

  return (
    <header className="flex w-full items-center justify-between gap-8 bg-primary px-10 py-4 font-helvetica text-white">
      <p className="text-[15px]">
        Последнее обновление <span className="font-bold">04.06.2024</span>
      </p>
      <div className="min-w-[240px] max-w-[620px] flex-1">
        <Input
          placeholder="Поиск"
          suffix={<IconSearch size={18} />}
          className="min-h-[55px] w-full border-[1px] border-[#DEE2E6] pl-[30px] pr-10"
        />
      </div>
      <div className="flex gap-16 justify-self-end">
        <div className="flex items-center gap-9">
          <Btn id="theme-toggle" title="Toggles light & dark" aria-label="auto" aria-live="polite">
            <img src={ThemeIcon} alt="theme" />
          </Btn>
          <Btn id="theme-toggle" title="Toggles light & dark" aria-label="auto" aria-live="polite">
            <PreviewIcon color="white" />
          </Btn>
          <Btn id="theme-toggle" title="Toggles light & dark" aria-label="auto" aria-live="polite">
            <NotificationIcon color="white" />
          </Btn>
        </div>
        <Btn>
          <div className="flex items-center gap-3">
            <div>
              {user?.imageUrl ? (
                <img src={user.imageUrl} alt="user" className="h-10 w-10 rounded-full" />
              ) : (
                <UserIcon color="white" />
              )}
            </div>
            <div className="flex flex-col items-start">
              <h3 className="typography-h5 text-nowrap">
                {user?.firstName} {user?.lastName}
              </h3>
              <p className="typography-small font-semibold uppercase">{user?.roles?.map((role) => role).join(', ')}</p>
            </div>
          </div>
        </Btn>
        <Btn onClick={handleLogout}>
          <LogoutIcon />
        </Btn>
      </div>
    </header>
  )
}

export default Header

const Btn = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
