import * as yup from 'yup'

const envSchema = yup.object({
  NODE_ENV: yup.string().oneOf(['development', 'production', 'test']),
  REACT_APP_BASE_URL: yup.string(),
  REACT_APP_BASE_URL_STAGING: yup.string(),
  REACT_APP_SENTRY_DSN: yup.string(),
  REACT_APP_ENVIRONMENT: yup.string(),
  REACT_APP_VERSION: yup.string(),
  REACT_APP_STAGING_HOST: yup.string(),
  SENTRY_AUTH_TOKEN: yup.string(),
  REACT_APP_YANDEX_API_KEY: yup.string(),
  VITE_API_URL: yup.string(),
  TINYMCE_API_KEY: yup.string(),
})

export const env = envSchema.validateSync(process.env, {
  abortEarly: false,
})
