export const Roles = {
  ADMIN: 'Admin',
  DIRECTOR: 'Director',
  EXPERTISE_DEPARTMENT: 'Expertise Department',
  ACCOUNTING_DEPARTMENT: 'Accounting Department',
  ORGANIZATIONAL_DEPARTMENT: 'Organizational Department',
  LEGAL_DEPARTMENT: 'Legal Department',
  HR_DEPARTMENT: 'HR Department',
}

export const allRoles = Object.keys(Roles)
export type RolesType = keyof typeof Roles

export const RoleKeys = allRoles.reduce<Record<RolesType, RolesType>>(
  (acc, role) => {
    acc[role as RolesType] = role as RolesType
    return acc
  },
  {} as Record<RolesType, RolesType>,
)

export const routeByRole: Record<RolesType, string[]> = {
  ADMIN: ['applications', 'inspection-cases', 'agreements', 'orders', 'employees'],
  DIRECTOR: ['applications', 'inspection-cases', 'agreements', 'orders', 'employees'],
  EXPERTISE_DEPARTMENT: ['applications'],
  ACCOUNTING_DEPARTMENT: ['inspection-cases', 'agreements'],
  ORGANIZATIONAL_DEPARTMENT: ['inspection-cases', 'orders'],
  LEGAL_DEPARTMENT: ['orders'],
  HR_DEPARTMENT: ['employees'],
}

export const roleByPath: Record<string, RolesType[]> = {
  applications: [RoleKeys.ADMIN, RoleKeys.DIRECTOR, RoleKeys.EXPERTISE_DEPARTMENT],
  'inspections-cases': [
    RoleKeys.ADMIN,
    RoleKeys.DIRECTOR,
    RoleKeys.ACCOUNTING_DEPARTMENT,
    RoleKeys.ORGANIZATIONAL_DEPARTMENT,
  ],
  agreements: [RoleKeys.ADMIN, RoleKeys.DIRECTOR, RoleKeys.ACCOUNTING_DEPARTMENT],
  orders: [RoleKeys.ADMIN, RoleKeys.DIRECTOR, RoleKeys.ORGANIZATIONAL_DEPARTMENT, RoleKeys.LEGAL_DEPARTMENT],
  employees: [RoleKeys.ADMIN, RoleKeys.DIRECTOR, RoleKeys.HR_DEPARTMENT],
}

export const openPaths = ['user']
