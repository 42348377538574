export const APP_NAME = 'GXP Inspection'

export const pathMapForDashboardMenu: {
  [key: string]: string
} = {
  statistics: 'statistics',
  applications: 'applications',
  agreement: 'agreement',
  orders: 'orders',
  pharmInspections: 'pharm-inspections',
  conclusions: 'conclusions',
  capaPlan: 'capa-plan',
  certificateRegistry: 'certificate-registry',
}

export enum LanguageCode {
  EN = 'EN',
  RU = 'RU',
  UZ = 'UZ',
}

export enum ImageType {
  TEMP = 'TEMP',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
