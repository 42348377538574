import { BaseClient } from '@/api/baseClient'
import { ApiResponseWithContent, LoginResponseType, LoginBodyType } from '@/types'
import { AxiosResponse } from 'axios'

const urls = {
  login: 'auth-api/v1/auth',
}

export class AuthAPI {
  constructor(private api: BaseClient) {}

  login = async (body: LoginBodyType) => {
    const result: AxiosResponse<ApiResponseWithContent<LoginResponseType>> = await this.api.post(urls.login, body)

    return result
  }
}

export default new AuthAPI(new BaseClient())
