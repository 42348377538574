import { createBrowserRouter } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import { Spin } from 'antd'

import MainLayout from '@/layout/Layout'
import paths from './paths'
import { LoadingOutlined } from '@ant-design/icons'
import ProtectedRoute from '@/auth/ProtectedRoute'

const withSuspense = (cb: () => Promise<{ default: React.ComponentType<any> }>) => {
  const L = lazy(cb)

  return (props: any) => (
    <Suspense fallback={<Spin indicator={<LoadingOutlined spin />} />}>
      <L {...props} />
    </Suspense>
  )
}

// main
const NotFound = withSuspense(() => import('@/pages/NotFound'))
const Home = withSuspense(() => import('@/pages/Home'))

// dashboard
const Statistics = withSuspense(() => import('@/pages/statistics/Statistics'))
const Applications = withSuspense(() => import('@/pages/applications/Applications'))
const ApplicationDetails = withSuspense(() => import('@/pages/applications/ApplicationDetails'))
const ReviewApplication = withSuspense(() => import('@/pages/applications/ReviewApplication'))
const Orders = withSuspense(() => import('@/pages/Orders'))
const Agreements = withSuspense(() => import('@/pages/agreements/Agreements'))
const CapaPlan = withSuspense(() => import('@/pages/CapaPlan'))
const CertificateRegistry = withSuspense(() => import('@/pages/CertificateRegistry'))
const Conclusions = withSuspense(() => import('@/pages/Conclusions'))
const PharmInspections = withSuspense(() => import('@/pages/PharmInspections'))
const InspectionCases = withSuspense(() => import('@/pages/inspectionCases/InspectionCases'))
const Employees = withSuspense(() => import('@/pages/employees/Employees'))
const EmployeeInfo = withSuspense(() => import('@/pages/employees/EmployeeInfo'))
const EmployeeAdd = withSuspense(() => import('@/pages/employees/EmployeeAdd'))
const Reports = withSuspense(() => import('@/pages/reports/Reports'))

export const routes = createBrowserRouter([
  {
    path: paths.HOME,
    element: <Home />,
  },
  {
    path: paths.MAIN,
    element: <MainLayout />,
    children: [
      {
        path: paths.STATISTICS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <Statistics />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.APPLICATIONS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'expert']}>
            <Applications />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.APPLICATION_DETAILS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'expert']}>
            <ApplicationDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.REVIEW_APPLICATION,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'expert']}>
            <ReviewApplication />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.INSPECTION_CASES,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'accountant', 'organizer', 'lawyer']}>
            <InspectionCases />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.AGREEMENTS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'accountant']}>
            <Agreements />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.ORDERS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'organizer', 'lawyer']}>
            <Orders />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.PHARM_INSPECTIONS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <PharmInspections />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.CONCLUSIONS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <Conclusions />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.CAPA_PLAN,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <CapaPlan />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.CERTIFICATE_REGISTRY,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <CertificateRegistry />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.EMPLOYEES,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'hr']}>
            <Employees />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.EMPLOYEE_INFO,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'hr']}>
            <EmployeeInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.EMPLOYEE_ADD,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director', 'hr']}>
            <EmployeeAdd />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.REPORTS,
        element: (
          <ProtectedRoute allowedRoles={['admin', 'director']}>
            <Reports />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
