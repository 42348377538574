import { useLocation } from 'react-router-dom'

const useRoleFromPath = () => {
  const location = useLocation() // URL manzilini olish

  // URL manzilidan path'ni ajratish
  const pathSegments = location.pathname.split('/') // '/' bo'yicha bo'lish
  const roleIndex = pathSegments.indexOf('cabinet') + 1 // 'cabinet' dan keyingi index

  // Agar 'cabinet' topilsa va keyingisi mavjud bo'lsa, qaytarish
  if (roleIndex > 0 && roleIndex < pathSegments.length) {
    return pathSegments[roleIndex] // GXP, Regulator yoki Admin-panel qaytarish
  }

  return null // Agar 'cabinet' yoki keyingi segment bo'lmasa, null qaytarish
}

export default useRoleFromPath
