import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'

import { ComponentToken } from './theme/componentToken'
import { GlobalToken } from './theme/globalToken'
import Root from './routes/Root'
import { NotificationProvider } from './hooks/useNotification'
import { store } from './redux/store'

const queryClient = new QueryClient()

function App() {
  return (
    <ConfigProvider
      theme={{
        components: ComponentToken,
        token: GlobalToken,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <NotificationProvider>
            <Root />
          </NotificationProvider>
        </Provider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
