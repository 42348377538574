import paths from '@/routes/paths'
import { Roles } from '@/types/auth'
import SideBarIcons from '@/utils/icons/sidebarIcons'

const { InspectionCasesIcon, RequestIcon, CertificateRegistryIcon, StatisticsIcon, EmployeesIcon, ReportsIcon } =
  SideBarIcons

export const menuItemsData = [
  {
    key: 'statistics',
    label: 'Статистика',
    icon: <StatisticsIcon />,
    path: paths.STATISTICS,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
  },
  {
    key: 'applications',
    label: 'Заявки',
    icon: <RequestIcon />,
    path: paths.APPLICATIONS,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR, Roles.EXPERT],
  },
  {
    key: 'inspectionCases',
    label: 'Инспекционные дела',
    icon: <InspectionCasesIcon />,
    path: paths.INSPECTION_CASES,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR, Roles.ACCOUNTANT, Roles.ORGANIZER],
    children: [
      {
        key: 'agreements',
        label: 'Договора',
        path: paths.AGREEMENTS,
        allowedRoles: [Roles.ADMIN, Roles.DIRECTOR, Roles.ACCOUNTANT],
      },
      {
        key: 'orders',
        label: 'Приказы',
        path: paths.ORDERS,
        allowedRoles: [Roles.ADMIN, Roles.DIRECTOR, Roles.ORGANIZER, Roles.LAWYER],
      },
      {
        key: 'pharmInspections',
        label: 'Фарминспекции',
        path: paths.PHARM_INSPECTIONS,
        allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
      },
      {
        key: 'conclusions',
        label: 'Заключения',
        path: paths.CONCLUSIONS,
        allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
      },
      {
        key: 'capaPlan',
        label: 'CAPA план',
        path: paths.CAPA_PLAN,
        allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
      },
    ],
  },
  {
    key: 'certificateRegistry',
    label: 'Реестр Сертификатов',
    icon: <CertificateRegistryIcon />,
    path: paths.CERTIFICATE_REGISTRY,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
  },
  {
    key: 'employees',
    label: 'кадры',
    icon: <EmployeesIcon />,
    path: paths.EMPLOYEES,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR, Roles.HR],
  },
  {
    key: 'reports',
    label: 'Отчеты',
    icon: <ReportsIcon />,
    path: paths.REPORTS,
    allowedRoles: [Roles.ADMIN, Roles.DIRECTOR],
  },
]
