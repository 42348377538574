import queryString from 'query-string'

// general routes
export const PAGE_NOT_FOUND = '404'
export const HOME = 'home'
export const AUTH = 'auth'
export const LOGIN = 'auth/login'

// cabinet routes
const MAIN = '/'
const STATISTICS = 'statistics'
const APPLICATIONS = 'applications'
const APPLICATION_DETAILS = 'applications/:id'
const APPLICATION_STEPS = 'applications/:id/steps'
const REVIEW_APPLICATION = 'applications/:id/review'
const AGREEMENTS = 'agreements'
const ORDERS = 'orders'
const INSPECTION_CASES = 'inspection-cases'
const PHARM_INSPECTIONS = 'pharm-inspections'
const CONCLUSIONS = 'conclusions'
const CAPA_PLAN = 'capaPlan'
const CERTIFICATE_REGISTRY = 'certificate-registry'
const EMPLOYEES = 'employees'
const EMPLOYEE_INFO = 'employees/:id'
const EMPLOYEE_ADD = 'employees/add'
const REPORTS = 'reports'

export const createWithQuery = (path: string, params: object) => {
  const query = queryString.stringify(params)
  return `${path}?${query}`
}

const paths = {
  PAGE_NOT_FOUND,
  AUTH,
  LOGIN,
  HOME,
  MAIN,
  STATISTICS,
  APPLICATIONS,
  APPLICATION_DETAILS,
  APPLICATION_STEPS,
  REVIEW_APPLICATION,
  AGREEMENTS,
  ORDERS,
  INSPECTION_CASES,
  PHARM_INSPECTIONS,
  CONCLUSIONS,
  CAPA_PLAN,
  CERTIFICATE_REGISTRY,
  EMPLOYEES,
  EMPLOYEE_INFO,
  EMPLOYEE_ADD,
  REPORTS,
}

export default paths
