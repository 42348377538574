import { UserType } from '@/types/userType'
import { createSlice } from '@reduxjs/toolkit'
import { getMeThunk } from '../thunks/userThunk'
import { eImzoLoginThunk } from '../thunks/eImzoLoginThunk'
import { credentialsLoginThunk } from '../thunks/credentialsLoginThunk'
import { RolesType } from '@/utils/roles'

export type UserState = {
  user: UserType | null
  isAuthed: boolean
  getMeLoading: boolean
  userRoles: RolesType[]
}

const initialState: UserState = {
  user: null,
  isAuthed: false,
  getMeLoading: false,
  userRoles: [],
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.isAuthed = true
    },
    clearUser: (state) => {
      state.user = null
      state.isAuthed = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeThunk.pending, (state) => {
      state.getMeLoading = true
    })
    builder.addCase(getMeThunk.fulfilled, (state, action) => {
      state.user = action.payload.result
      state.isAuthed = true
      state.userRoles = action.payload.result.roles
      state.getMeLoading = false
    })
    builder.addCase(getMeThunk.rejected, (state) => {
      state.getMeLoading = false
    })
    builder.addCase(eImzoLoginThunk.fulfilled, (state, action) => {
      state.isAuthed = true
      state.userRoles = action.payload.result.roles
    })
    builder.addCase(credentialsLoginThunk.fulfilled, (state, action) => {
      state.isAuthed = true
      state.userRoles = action.payload.result.roles
    })
  },
})

const { actions, reducer } = slice
export const { setUser, clearUser } = actions
export default reducer
