import { useAppDispatch } from '@/redux/store'
import { useMutation } from './useQuery'
import EImzoAPI from '@/api/requests/v1/eImzo'
import { useCustomNotification } from './useNotification'
import { useTranslation } from 'react-i18next'
import { eImzoLoginThunk } from '@/redux/thunks/eImzoLoginThunk'
import { useNavigate } from 'react-router-dom'
import { TokenService } from '@/utils/storage'
import paths from '@/routes/paths'

const useEImzoLogin = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openNotification } = useCustomNotification()

  const eImzoEntrance = useMutation({
    mutationFn: EImzoAPI.eImzoEntrance,
    onError: () => {
      openNotification('error', `${t('errorsMsg.wrongSomething')}`, `${t('errorsMsg.tryAgain')}`)
    },
    onSuccess: (data) => {
      window.open(data.data.result, '_self')
    },
  })

  const eImzoLogin = useMutation({
    mutationFn: async (code: string) => {
      return await dispatch(eImzoLoginThunk(code)).unwrap()
    },
    onError: () => {
      openNotification('error', `${t('errorsMsg.wrongSomething')}`, `${t('errorsMsg.tryAgain')}`)
      navigate(paths.HOME)
    },
    onSuccess: (data) => {
      TokenService.setToken(
        data.result.accessToken,
        data.result.refreshToken,
        data.result.accessTokenExpiry,
        data.result.refreshTokenExpiry,
      )
      navigate(paths.MAIN)
    },
  })

  return { eImzoEntrance, eImzoLogin }
}

export default useEImzoLogin
