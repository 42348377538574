type IconProps = {
  size?: number
  color?: string
  width?: number
  height?: number
  className?: string
}

const PharmLogo = ({ size = 220, width = size, height = 56, className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 220 57"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M60.185 0H51.0672H51.0311H45.0487C44.0396 0 43.2467 0.792857 43.2467 1.80195V8.64935C43.2467 9.65844 44.0396 10.4513 45.0487 10.4513H51.0311H51.0672H60.0409C67.8253 10.4513 74.0961 16.7581 74.0961 24.5065C74.0961 32.2909 67.7893 38.5617 60.0409 38.5617H44.4396V49.013H60.185C73.7357 49.013 84.6915 38.0571 84.6915 24.5065C84.6915 10.9558 73.7357 0 60.185 0ZM39.6428 0H33.6604H33.6243H24.5065C10.9558 0 0 10.9919 0 24.5065C0 38.0211 10.9558 49.013 24.5065 49.013H40.2519V38.5617H24.6506C16.8662 38.5617 10.5954 32.2549 10.5954 24.5065C10.5954 16.7221 16.9023 10.4513 24.6506 10.4513H33.6243H33.6604H39.6428C40.6519 10.4513 41.4448 9.65844 41.4448 8.64935V1.80195C41.4448 0.792857 40.6519 0 39.6428 0Z"
        fill="url(#paint0_linear_441_3790)"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M39.9672 20.3619L33.3 16.8661C32.0746 16.2174 30.9935 16.4697 30.9935 17.4788C32.7297 32.256 32.6805 40.5425 30.9935 55.3197C30.9935 56.3288 32.0386 56.581 33.3 55.9323L39.9672 52.4366C40.76 52.0401 41.3727 51.1031 41.3727 50.3823V22.4161C41.3727 21.6953 40.76 20.7583 39.9672 20.3619ZM51.3915 16.8661L44.7243 20.3619C43.9314 20.7583 43.3188 21.6953 43.3188 22.4161V50.3823C43.3188 51.1031 43.9314 52.0401 44.7243 52.4366L51.3915 55.9323C52.6168 56.581 53.698 56.3288 53.698 55.3197C52.0594 40.6225 52.108 32.3326 53.698 17.4788C53.698 16.5057 52.6529 16.2535 51.3915 16.8661Z"
        fill="url(#paint1_linear_441_3790)"
      />
      <path
        d="M106.586 13.1209H97.3177V10.6696L102.399 3.87041H97.3177V1.18652H106.461V3.63781L101.361 10.437H106.586V13.1209Z"
        fill="url(#paint2_linear_441_3790)"
      />
      <path
        d="M119.947 13.1209H116.457L115.867 11.4032H111.161L110.571 13.1209H107.082L111.573 1.18652H115.438L119.947 13.1209ZM115.026 8.7193L113.505 4.22826L111.984 8.7193H115.026Z"
        fill="url(#paint3_linear_441_3790)"
      />
      <path
        d="M131.009 13.1209H127.484L125.48 9.11294H123.906V13.1209H120.828V1.18652H126.804C129.452 1.18652 130.955 2.94 130.955 5.15868C130.955 7.23421 129.685 8.34355 128.575 8.73719L131.009 13.1209ZM126.339 6.42905C127.144 6.42905 127.824 5.98173 127.824 5.14078C127.824 4.33562 127.144 3.87041 126.339 3.87041H123.906V6.42905H126.339Z"
        fill="url(#paint4_linear_441_3790)"
      />
      <path
        d="M138.311 13.3356C134.465 13.3356 132.657 11.2243 132.657 8.28988V1.18652H135.789V8.18252C135.789 9.59604 136.63 10.6159 138.311 10.6159C139.958 10.6159 140.816 9.59604 140.816 8.18252V1.18652H143.93V8.27198C143.93 11.2422 142.123 13.3356 138.311 13.3356Z"
        fill="url(#paint5_linear_441_3790)"
      />
      <path
        d="M156.258 13.1209H152.733L150.729 9.11294H149.154V13.1209H146.077V1.18652H152.053C154.701 1.18652 156.204 2.94 156.204 5.15868C156.204 7.23421 154.934 8.34355 153.824 8.73719L156.258 13.1209ZM151.588 6.42905C152.393 6.42905 153.073 5.98173 153.073 5.14078C153.073 4.33562 152.393 3.87041 151.588 3.87041H149.154V6.42905H151.588Z"
        fill="url(#paint6_linear_441_3790)"
      />
      <path
        d="M109.288 29.8206H105.799L105.208 28.1029H100.503L99.9121 29.8206H96.4231L100.914 17.8863H104.779L109.288 29.8206ZM104.367 25.419L102.847 20.928L101.326 25.419H104.367Z"
        fill="url(#paint7_linear_441_3790)"
      />
      <path
        d="M123.714 29.8206H120.637V22.0552L117.613 29.8206H116.253L113.247 22.0552V29.8206H110.169V17.8863H114.446L116.933 24.3634L119.438 17.8863H123.714V29.8206Z"
        fill="url(#paint8_linear_441_3790)"
      />
      <path
        d="M137.455 29.8206H133.966L133.375 28.1029H128.669L128.079 29.8206H124.59L129.081 17.8863H132.946L137.455 29.8206ZM132.534 25.419L131.013 20.928L129.492 25.419H132.534Z"
        fill="url(#paint9_linear_441_3790)"
      />
      <path
        d="M146.209 29.8206H138.336V17.8863H141.414V27.1367H146.209V29.8206Z"
        fill="url(#paint10_linear_441_3790)"
      />
      <path d="M150.884 29.8206H147.807V17.8863H150.884V29.8206Z" fill="url(#paint11_linear_441_3790)" />
      <path
        d="M159.365 29.8206H156.288V25.0075L151.761 17.8863H155.232L157.826 22.2878L160.385 17.8863H163.874L159.365 25.0075V29.8206Z"
        fill="url(#paint12_linear_441_3790)"
      />
      <path
        d="M169.819 30.0353C166.168 30.0353 163.431 27.4946 163.431 23.8624C163.431 20.2302 166.168 17.6894 169.819 17.6894C173.469 17.6894 176.188 20.2302 176.188 23.8624C176.188 27.4946 173.469 30.0353 169.819 30.0353ZM169.819 27.3156C171.787 27.3156 173.057 25.7948 173.057 23.8624C173.057 21.9121 171.787 20.4091 169.819 20.4091C167.833 20.4091 166.562 21.9121 166.562 23.8624C166.562 25.7948 167.833 27.3156 169.819 27.3156Z"
        fill="url(#paint13_linear_441_3790)"
      />
      <path
        d="M183.08 29.8206H180.002V20.5701H176.656V17.8863H186.408V20.5701H183.08V29.8206Z"
        fill="url(#paint14_linear_441_3790)"
      />
      <path d="M195.745 29.8206H187.873V17.8863H190.95V27.1367H195.745V29.8206Z" fill="url(#paint15_linear_441_3790)" />
      <path
        d="M208.938 29.8206H205.449L204.858 28.1029H200.152L199.562 29.8206H196.073L200.564 17.8863H204.429L208.938 29.8206ZM204.017 25.419L202.496 20.928L200.975 25.419H204.017Z"
        fill="url(#paint16_linear_441_3790)"
      />
      <path
        d="M220 29.8206H216.475L214.471 25.8127H212.897V29.8206H209.819V17.8863H215.795C218.443 17.8863 219.946 19.6397 219.946 21.8584C219.946 23.934 218.676 25.0433 217.567 25.4369L220 29.8206ZM215.33 23.1288C216.135 23.1288 216.815 22.6815 216.815 21.8405C216.815 21.0354 216.135 20.5701 215.33 20.5701H212.897V23.1288H215.33Z"
        fill="url(#paint17_linear_441_3790)"
      />
      <path
        d="M111.238 46.5203H108.161V38.755L105.137 46.5203H103.777L100.771 38.755V46.5203H97.6935V34.586H101.97L104.457 41.0631L106.962 34.586H111.238V46.5203Z"
        fill="url(#paint18_linear_441_3790)"
      />
      <path
        d="M124.979 46.5203H121.49L120.899 44.8027H116.194L115.603 46.5203H112.114L116.605 34.586H120.47L124.979 46.5203ZM120.058 42.1188L118.537 37.6277L117.017 42.1188H120.058Z"
        fill="url(#paint19_linear_441_3790)"
      />
      <path
        d="M136.041 46.5203H132.516L130.512 42.5124H128.938V46.5203H125.86V34.586H131.836C134.485 34.586 135.987 36.3395 135.987 38.5581C135.987 40.6337 134.717 41.743 133.608 42.1367L136.041 46.5203ZM131.371 39.8285C132.176 39.8285 132.856 39.3812 132.856 38.5403C132.856 37.7351 132.176 37.2699 131.371 37.2699H128.938V39.8285H131.371Z"
        fill="url(#paint20_linear_441_3790)"
      />
      <path
        d="M148.479 46.5203H144.704L141.572 42.0293L140.767 43.0313V46.5203H137.69V34.586H140.767V39.4886L144.435 34.586H148.228L143.594 40.1685L148.479 46.5203Z"
        fill="url(#paint21_linear_441_3790)"
      />
      <path
        d="M160.991 46.5203H157.502L156.912 44.8027H152.206L151.615 46.5203H148.126L152.617 34.586H156.482L160.991 46.5203ZM156.071 42.1188L154.55 37.6277L153.029 42.1188H156.071Z"
        fill="url(#paint22_linear_441_3790)"
      />
      <path
        d="M170.765 46.5203H161.497V44.0691L166.578 37.2699H161.497V34.586H170.64V37.0373L165.541 43.8365H170.765V46.5203Z"
        fill="url(#paint23_linear_441_3790)"
      />
      <path d="M175.609 46.5203H172.531V34.586H175.609V46.5203Z" fill="url(#paint24_linear_441_3790)" />
      <defs>
        <linearGradient
          id="paint0_linear_441_3790"
          x1="13.7176"
          y1="2.9821"
          x2="69.781"
          y2="47.1171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_441_3790"
          x1="34.671"
          y1="18.9292"
          x2="57.4735"
          y2="24.8584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_441_3790"
          x1="119.805"
          y1="2.29046"
          x2="186.614"
          y2="78.3844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D384" />
          <stop offset="1" stop-color="#078CBE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const PharmLogoWhite = ({ size = 183, width = size, height = 47, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 183 47"
    fill="none"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.063 0H42.4786H42.4486H37.4723C36.6329 0 35.9734 0.659513 35.9734 1.49889V7.19468C35.9734 8.03406 36.6329 8.69358 37.4723 8.69358H42.4486H42.4786H49.9431C56.4183 8.69358 61.6345 13.9397 61.6345 20.3849C61.6345 26.8602 56.3883 32.0763 49.9431 32.0763H36.9656V40.7699H50.063C61.3347 40.7699 70.4479 31.6566 70.4479 20.3849C70.4479 9.11327 61.3347 0 50.063 0ZM32.9756 0H27.9993H27.9693H20.3849C9.11327 0 0 9.14325 0 20.3849C0 31.6266 9.11327 40.7699 20.3849 40.7699H33.4823V32.0763H20.5048C14.0296 32.0763 8.81349 26.8302 8.81349 20.3849C8.81349 13.9097 14.0596 8.69358 20.5048 8.69358H27.9693H27.9993H32.9756C33.815 8.69358 34.4745 8.03406 34.4745 7.19468V1.49889C34.4745 0.659513 33.815 0 32.9756 0Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.2455 16.9375L27.6996 14.0297C26.6803 13.4901 25.781 13.6999 25.781 14.5393C27.2253 26.8312 27.1843 33.7241 25.781 46.016C25.781 46.8554 26.6504 47.0652 27.6996 46.5256L33.2455 43.6178C33.905 43.288 34.4146 42.5086 34.4146 41.9091V18.6462C34.4146 18.0467 33.905 17.2673 33.2455 16.9375ZM42.7485 14.0297L37.2026 16.9375C36.5431 17.2673 36.0334 18.0467 36.0334 18.6462V41.9091C36.0334 42.5086 36.5431 43.288 37.2026 43.6178L42.7485 46.5256C43.7677 47.0652 44.6671 46.8554 44.6671 46.016C43.304 33.7906 43.3444 26.895 44.6671 14.5393C44.6671 13.7299 43.7977 13.52 42.7485 14.0297Z"
      fill="white"
    />
    <path
      d="M88.8624 10.8458H81.1693V8.82894L85.3872 3.23478H81.1693V1.02655H88.7584V3.0434L84.5258 8.63756H88.8624V10.8458Z"
      fill="white"
    />
    <path
      d="M99.952 10.8458H97.056L96.5659 9.43252H92.66L92.1699 10.8458H89.2738L93.0016 1.02655H96.2095L99.952 10.8458ZM95.8679 7.2243L94.6055 3.52921L93.3431 7.2243H95.8679Z"
      fill="white"
    />
    <path
      d="M109.134 10.8458H106.208L104.545 7.54817H103.238V10.8458H100.684V1.02655H105.644C107.842 1.02655 109.09 2.46926 109.09 4.29472C109.09 6.00242 108.035 6.91515 107.114 7.23902L109.134 10.8458ZM105.258 5.33995C105.926 5.33995 106.491 4.97191 106.491 4.28C106.491 3.61753 105.926 3.23478 105.258 3.23478H103.238V5.33995H105.258Z"
      fill="white"
    />
    <path
      d="M115.196 11.0224C112.003 11.0224 110.503 9.28531 110.503 6.87098V1.02655H113.102V6.78266C113.102 7.94565 113.8 8.78478 115.196 8.78478C116.562 8.78478 117.275 7.94565 117.275 6.78266V1.02655H119.859V6.85626C119.859 9.30003 118.359 11.0224 115.196 11.0224Z"
      fill="white"
    />
    <path
      d="M130.092 10.8458H127.166L125.503 7.54817H124.196V10.8458H121.641V1.02655H126.602C128.8 1.02655 130.047 2.46926 130.047 4.29472C130.047 6.00242 128.993 6.91515 128.072 7.23902L130.092 10.8458ZM126.215 5.33995C126.884 5.33995 127.448 4.97191 127.448 4.28C127.448 3.61753 126.884 3.23478 126.215 3.23478H124.196V5.33995H126.215Z"
      fill="white"
    />
    <path
      d="M91.105 24.5858H88.2089L87.7188 23.1726H83.8129L83.3228 24.5858H80.4268L84.1545 14.7666H87.3624L91.105 24.5858ZM87.0208 20.9644L85.7584 17.2693L84.4961 20.9644H87.0208Z"
      fill="white"
    />
    <path
      d="M103.079 24.5858H100.525V18.1967L98.0149 24.5858H96.8861L94.3911 18.1967V24.5858H91.8366V14.7666H95.3861L97.4505 20.0958L99.5297 14.7666H103.079V24.5858Z"
      fill="white"
    />
    <path
      d="M114.484 24.5858H111.588L111.098 23.1726H107.192L106.702 24.5858H103.806L107.534 14.7666H110.742L114.484 24.5858ZM110.4 20.9644L109.138 17.2693L107.876 20.9644H110.4Z"
      fill="white"
    />
    <path d="M121.751 24.5858H115.216V14.7666H117.771V22.3776H121.751V24.5858Z" fill="white" />
    <path d="M125.631 24.5858H123.077V14.7666H125.631V24.5858Z" fill="white" />
    <path
      d="M132.671 24.5858H130.116V20.6258L126.359 14.7666H129.24L131.394 18.3881L133.517 14.7666H136.413L132.671 20.6258V24.5858Z"
      fill="white"
    />
    <path
      d="M141.348 24.7625C138.318 24.7625 136.046 22.6721 136.046 19.6836C136.046 16.6951 138.318 14.6047 141.348 14.6047C144.377 14.6047 146.635 16.6951 146.635 19.6836C146.635 22.6721 144.377 24.7625 141.348 24.7625ZM141.348 22.5248C142.981 22.5248 144.036 21.2735 144.036 19.6836C144.036 18.0789 142.981 16.8423 141.348 16.8423C139.699 16.8423 138.645 18.0789 138.645 19.6836C138.645 21.2735 139.699 22.5248 141.348 22.5248Z"
      fill="white"
    />
    <path d="M152.355 24.5858H149.801V16.9748H147.023V14.7666H155.117V16.9748H152.355V24.5858Z" fill="white" />
    <path d="M162.868 24.5858H156.333V14.7666H158.888V22.3776H162.868V24.5858Z" fill="white" />
    <path
      d="M173.818 24.5858H170.922L170.432 23.1726H166.526L166.036 24.5858H163.14L166.867 14.7666H170.075L173.818 24.5858ZM169.734 20.9644L168.471 17.2693L167.209 20.9644H169.734Z"
      fill="white"
    />
    <path
      d="M183 24.5858H180.074L178.411 21.2882H177.104V24.5858H174.55V14.7666H179.51C181.708 14.7666 182.955 16.2093 182.955 18.0348C182.955 19.7425 181.901 20.6552 180.98 20.9791L183 24.5858ZM179.124 19.08C179.792 19.08 180.356 18.712 180.356 18.0201C180.356 17.3576 179.792 16.9748 179.124 16.9748H177.104V19.08H179.124Z"
      fill="white"
    />
    <path
      d="M92.7238 38.3259H90.1693V31.9368L87.6594 38.3259H86.5307L84.0357 31.9368V38.3259H81.4812V28.5067H85.0307L87.0951 33.8359L89.1743 28.5067H92.7238V38.3259Z"
      fill="white"
    />
    <path
      d="M104.129 38.3259H101.233L100.743 36.9127H96.8369L96.3468 38.3259H93.4508L97.1785 28.5067H100.386L104.129 38.3259ZM100.045 34.7044L98.7825 31.0093L97.5201 34.7044H100.045Z"
      fill="white"
    />
    <path
      d="M113.311 38.3259H110.385L108.722 35.0283H107.415V38.3259H104.861V28.5067H109.821C112.019 28.5067 113.267 29.9494 113.267 31.7748C113.267 33.4825 112.212 34.3953 111.291 34.7191L113.311 38.3259ZM109.435 32.8201C110.103 32.8201 110.668 32.452 110.668 31.7601C110.668 31.0977 110.103 30.7149 109.435 30.7149H107.415V32.8201H109.435Z"
      fill="white"
    />
    <path
      d="M123.635 38.3259H120.501L117.902 34.6308L117.234 35.4552V38.3259H114.679V28.5067H117.234V32.5404L120.278 28.5067H123.427L119.58 33.0998L123.635 38.3259Z"
      fill="white"
    />
    <path
      d="M134.021 38.3259H131.124L130.634 36.9127H126.728L126.238 38.3259H123.342L127.07 28.5067H130.278L134.021 38.3259ZM129.936 34.7044L128.674 31.0093L127.412 34.7044H129.936Z"
      fill="white"
    />
    <path
      d="M142.133 38.3259H134.44V36.3091L138.658 30.7149H134.44V28.5067H142.029V30.5235L137.797 36.1177H142.133V38.3259Z"
      fill="white"
    />
    <path d="M146.154 38.3259H143.599V28.5067H146.154V38.3259Z" fill="white" />
  </svg>
)

export default PharmLogo
