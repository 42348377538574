import { getUserState } from '@/redux/selectors/user'
import { useAppSelector } from '@/redux/store'
import { TokenService } from '@/utils/storage'
import { useMemo } from 'react'
import useRoleFromPath from './useRoleFromPath'
import { roleByPath, routeByRole, openPaths } from '@/utils/roles'

const useAuthRoute = () => {
  const token = TokenService.getToken()
  const roleRouteName = useRoleFromPath()

  const { isAuthed, user, userRoles } = useAppSelector(getUserState)

  const isAuthenticated = useMemo(() => isAuthed || !!token, [isAuthed, token])

  const rejectRoute = useMemo(() => {
    if (isAuthenticated && userRoles) {
      if (roleRouteName) {
        const roles = roleByPath[roleRouteName]
        if (roles) {
          if (roles.some((role) => userRoles.includes(role))) {
            return false
          } else {
            return routeByRole[userRoles[0]] ? routeByRole[userRoles[0]][0] : 'notFound'
          }
        } else {
          if (openPaths.includes(roleRouteName)) {
            return false
          }
          return routeByRole[userRoles[0]] ? routeByRole[userRoles[0]][0] : 'notFound'
        }
      } else {
        return routeByRole[userRoles[0]] ? routeByRole[userRoles[0]][0] : 'notFound'
      }
    }
    return false
  }, [isAuthenticated, roleRouteName, userRoles])

  return { isAuthenticated, user, userRoles, rejectRoute }
}

export default useAuthRoute
