import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { buildParams } from './helpers'
import { TokenService } from '@/utils/storage'
import { env } from '@/utils/env'
import i18n from '@/i18n'

const API_URL = env.VITE_API_URL

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean
    unhandled?: boolean
  }
}

export class HTTPError extends Error {
  constructor(
    public status: number,
    public cause: string,
  ) {
    super(cause)
  }
}
export class BaseClient {
  private baseUrl = API_URL
  private axios: AxiosInstance

  constructor() {
    this.axios = Axios.create({
      baseURL: this.baseUrl,
    })

    // Request interceptor for attaching the token
    this.axios.interceptors.request.use(this.attachToken)
    // Response interceptor for handling errors
    this.axios.interceptors.response.use((response: AxiosResponse) => response, this.onApiError)
  }

  // Request interceptor for attaching token
  private attachToken = async (req: InternalAxiosRequestConfig) => {
    const token = TokenService.getToken()
    const accessTokenExpiry = TokenService.getAccessTokenExpiry()
    const currentTime = Date.now() / 1000

    // Token bor va hali yaroqli bo'lsa
    if (token && currentTime < accessTokenExpiry) {
      req.headers = req.headers || {}
      req.headers['Authorization'] = `Bearer ${token}`
    }
    // Token muddati tugagan bo'lsa, yangilashga harakat qilamiz
    else if (token && currentTime >= accessTokenExpiry) {
      try {
        await this.refreshToken() // Tokenni yangilash
        const newToken = TokenService.getToken() // Yangilangan tokenni olish
        if (newToken) {
          req.headers['Authorization'] = `Bearer ${newToken}`
        }
      } catch (error) {
        console.error('Failed to refresh token', error)
        // Yangilanishda xato bo'lsa, foydalanuvchini logout qilish yoki boshqa mantiq qo'shish mumkin
      }
    }

    req.headers['Lang'] = i18n.language

    return req
  }

  // API Error handler
  private onApiError = async (error: AxiosError) => {
    const originalRequest = error.config

    if (originalRequest && !originalRequest._retry) {
      switch (error.response?.status) {
        case 401: {
          originalRequest._retry = true
          const success = await this.refreshToken()
          if (success && originalRequest) {
            const newToken = TokenService.getToken()
            if (newToken) {
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`
              return this.axios(originalRequest)
            }
          }
          break
        }
        case 502:
          // Other error handling
          return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }

  // Refresh token method
  private refreshToken = async (): Promise<boolean> => {
    try {
      const refreshToken = TokenService.getRefreshToken()
      const refreshTokenExpiry = TokenService.getRefreshTokenExpiry()
      const currentTime = Date.now() / 1000

      if (!refreshToken || currentTime >= refreshTokenExpiry) {
        TokenService.clearTokens()
        return false // Refresh token ham tugagan bo'lsa foydalanuvchi tizimdan chiqadi
      }

      const response = await this.axios.post('/auth/refresh', { refreshToken })

      const newAccessToken = response.data.accessToken
      const newRefreshToken = response.data.refreshToken
      const newAccessTokenExpiry = response.data.accessTokenExpiry
      const newRefreshTokenExpiry = response.data.refreshTokenExpiry

      TokenService.setToken(newAccessToken, newRefreshToken, newAccessTokenExpiry, newRefreshTokenExpiry)

      return true
    } catch (error) {
      console.warn('refreshToken error:', error)
      TokenService.clearTokens()
      return false
    }
  }

  setAccessToken = (token: string) => {
    const newToken = `Bearer ${token}`
    this.axios.defaults.headers.common.Authorization = newToken

    return newToken
  }

  get = async <T, K, C>(url: string, params?: K, config?: C): Promise<AxiosResponse<T>> => {
    const queryParams = params ? buildParams(params) : ''
    return this.axios.get(url + queryParams, {
      ...config,
    })
  }

  delete = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.delete(url, { params: data })
  }

  post = async <T, K>(url: string, data?: K, config?: AxiosRequestConfig<K>): Promise<AxiosResponse<T>> => {
    return this.axios.post(url, data, config)
  }

  patch = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.patch(url, data)
  }

  put = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.put(url, data)
  }
}

export const baseApiClient = new BaseClient()
