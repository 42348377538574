import { Menu } from 'antd'
import styled from 'styled-components'

export const CustomMenu = styled(Menu)<{ $isCollapsed: boolean }>`
  border: none !important;
  transition:
    border-color 0.3s,
    background 0.3s,
    padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  .ant-menu-item {
    display: flex !important;
    gap: 16px;
    align-items: center !important;
    justify-content: center !important;
    padding: ${({ $isCollapsed }) => ($isCollapsed ? '30px 16px' : '30px 30px 30px 37px')} !important;
    margin: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: auto;
  }
  .ant-menu-title-content {
    display: ${({ $isCollapsed }) => ($isCollapsed ? 'none' : 'block')} !important;
    text-transform: uppercase !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    width: 150px;
    line-height: 110%;
    white-space: normal;
  }
  .ant-menu-item-selected {
    background: linear-gradient(114deg, #00d384 13.03%, #078cbe 86.69%) !important;
    color: #fff !important;
    .ant-menu-title-content {
      color: #fff !important;
    }
  }
  .ant-menu-item-selected .ant-menu-item-icon {
    path {
      fill: #fff !important;
    }
  }

  .ant-menu .ant-menu-item .ant-menu-title-content {
    width: 200px;
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center !important;
    gap: 16px;
    padding: ${({ $isCollapsed }) => ($isCollapsed ? '30px 16px' : '30px 30px 30px 37px')} !important;
    margin: 0;
    border-radius: 0 !important;
    height: auto;
  }
  .ant-menu-submenu {
    .ant-menu-item {
      margin-bottom: 0 !important;
      padding: ${({ $isCollapsed }) => ($isCollapsed ? '30px 16px' : '16px 16px 16px 20px')} !important;
      .ant-menu-title-content {
        padding-left: 64px !important;
      }
      svg {
        fill: #2dc492 !important;
      }
    }
    .ant-menu-sub .ant-menu-title-content {
      text-transform: none !important;
    }
    &-selected {
      .ant-menu-submenu-title {
        background: linear-gradient(114deg, #00d384 13.03%, #078cbe 86.69%) !important;
        color: #fff !important;
        .ant-menu-item-icon {
          path {
            fill: #fff !important;
          }
        }
      }
      .ant-menu-submenu-arrow {
        color: #fff !important;
      }
    }
    .ant-menu-item-selected {
      background: linear-gradient(114deg, #00d384 13.03%, #078cbe 86.69%) !important;
      position: relative !important;
      &::before {
        display: none;
      }
      .ant-menu-title-content {
        color: #fff !important;
      }
      .ant-menu-item-icon {
        path {
          fill: #fff !important;
        }
      }
      svg {
        fill: #fff !important;
      }
    }
    .ant-menu-submenu-arrow {
      color: #2dc492;
      font-size: 18px !important;
    }
    &-popup {
      border-radius: 0 !important;
    }
  }
`

export const CollapsedToggle = styled.button`
  position: absolute;
  background-color: #ffffff;
  color: white;
  top: 20px;
  width: 20px;
  height: 45px;
  right: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  outline: none;
  border: none;
  cursor: pointer;
`
